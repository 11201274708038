import React from "react";
import theme from "theme";
import { Theme, Link, Image, Box, Section, Text, Icon, Hr, LinkBox, Strong, Button, Input } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, SocialMedia, Formspree } from "@quarkly/components";
import * as Components from "components";
import { MdArrowDownward, MdLocationOn, MdEmail, MdPhone } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Cryostudio Joensuu - hyvinvointia koko keholle
			</title>
			<meta name={"description"} content={"Tervetuloa Cryostudioon Joensuussa, missä tarjoamme sinulle yksilöllistä ja tehokasta hoitoa kylmä- ja sähköhoidon keinoin. Hoidamme urheiluvammat, ihonhoito, stressin, laihdutuksen, kehonmuokkauksen ja niveloireet ammattitaidolla ja vaivattomasti. Kokeile kylmähoitoa ja sähköhoitoa - ne voivat olla avain hyvinvointiisi. Tutustu lisää!"} />
			<meta property={"og:title"} content={"Cryostudio Joensuu - hyvinvointia koko keholle"} />
			<meta property={"og:description"} content={"Tervetuloa Cryostudioon Joensuussa, missä tarjoamme sinulle yksilöllistä ja tehokasta hoitoa kylmä- ja sähköhoidon keinoin. Hoidamme urheiluvammat, ihonhoito, stressin, laihdutuksen, kehonmuokkauksen ja niveloireet ammattitaidolla ja vaivattomasti. Kokeile kylmähoitoa ja sähköhoitoa - ne voivat olla avain hyvinvointiisi. Tutustu lisää!"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6448bc7abf72b300207e257c/images/cryostudio.logo_500x500_valk.png?v=2023-04-28T09:11:42.808Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6448bc7abf72b300207e257c/images/cryostudio.logo_500x500_valk.png?v=2023-04-28T09:11:42.808Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6448bc7abf72b300207e257c/images/cryostudio.logo_500x500_valk.png?v=2023-04-28T09:11:42.808Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6448bc7abf72b300207e257c/images/cryostudio.logo_500x500_valk.png?v=2023-04-28T09:11:42.808Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6448bc7abf72b300207e257c/images/cryostudio.logo_500x500_valk.png?v=2023-04-28T09:11:42.808Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6448bc7abf72b300207e257c/images/cryostudio.logo_500x500_valk.png?v=2023-04-28T09:11:42.808Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6448bc7abf72b300207e257c/images/cryostudio.logo_500x500_valk.png?v=2023-04-28T09:11:42.808Z"} />
			<meta name={"msapplication-TileColor"} content={"#15209b"} />
		</Helmet>
		<Section
			id="koti"
			quarkly-title="Header"
			sm-flex-direction="row"
			md-flex-direction="row"
			md-align-items="center"
			md-justify-content="flex-start"
			position="absolute"
			height="104px"
			sm-justify-content="center"
			z-index="100"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				sm-flex-direction="row"
				sm-align-items="center"
				md-display="flex"
				md-flex="0 0 auto"
				justify-content="space-between"
				sm-display="flex"
				sm-margin="0px 5px 0px 5px"
			/>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				display="flex"
				align-items="center"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-color="LightGray"
				md-padding="0px 0px 0px 0"
				md-flex-direction="column"
			>
				<Image
					height="auto"
					src="https://uploads.quarkly.io/6448bc7abf72b300207e257c/images/Cryostudio.logo_teksti_valk%20%281%29.png?v=2023-04-26T06:20:54.852Z"
					width="200px"
					md-width="145px"
					srcSet="https://smartuploads.quarkly.io/6448bc7abf72b300207e257c/images/Cryostudio.logo_teksti_valk%20%281%29.png?v=2023-04-26T06%3A20%3A54.852Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6448bc7abf72b300207e257c/images/Cryostudio.logo_teksti_valk%20%281%29.png?v=2023-04-26T06%3A20%3A54.852Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6448bc7abf72b300207e257c/images/Cryostudio.logo_teksti_valk%20%281%29.png?v=2023-04-26T06%3A20%3A54.852Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6448bc7abf72b300207e257c/images/Cryostudio.logo_teksti_valk%20%281%29.png?v=2023-04-26T06%3A20%3A54.852Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6448bc7abf72b300207e257c/images/Cryostudio.logo_teksti_valk%20%281%29.png?v=2023-04-26T06%3A20%3A54.852Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6448bc7abf72b300207e257c/images/Cryostudio.logo_teksti_valk%20%281%29.png?v=2023-04-26T06%3A20%3A54.852Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6448bc7abf72b300207e257c/images/Cryostudio.logo_teksti_valk%20%281%29.png?v=2023-04-26T06%3A20%3A54.852Z&quality=85&w=3200 3200w"
					sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box display="flex" align-items="center">
				<Link
					href="https://vello.fi/cryostudio-joensuu/"
					color="#ffffff"
					font="--base"
					text-decoration-line="initial"
					target="_blank"
					md-font="normal 400 12px/1.5 &quot;Inter&quot;, sans-serif"
					sm-font="normal 500 14px/1.5 &quot;Inter&quot;, sans-serif"
				>
					Varaa aika{" "}
				</Link>
			</Box>
		</Section>
		<Section
			background="linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://uploads.quarkly.io/6448bc7abf72b300207e257c/images/pmainKuva.jpg?v=2023-11-22T19:35:14.931Z) center/cover"
			color="--light"
			font="--base"
			md-background="linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://uploads.quarkly.io/6448bc7abf72b300207e257c/images/Nimeto%CC%88n%20malli.jpg?v=2023-09-08T06:23:31.352Z) 20%/cover"
			quarkly-title="Hero Section"
			height="100vh"
			position="relative"
			z-index="0"
			align-items="center"
		>
			<Box margin="-16px -16px -16px -16px" display="flex">
				<Box display="flex" width="100%" md-width="100%" lg-width="100%">
					<Box display="flex" flex-direction="column" padding="16px 16px 16px 16px">
						<Text
							color="--lightD2"
							letter-spacing="1px"
							text-transform="uppercase"
							margin="0"
							sm-font="14px/27px Inter, sans-serif"
							font="22px/27px Inter, sans-serif"
							md-font="20px/27px Inter, sans-serif"
						>
							Hyvinvointia mielelle ja keholle
						</Text>
						<Text
							as="h1"
							font="normal 700 72px/1.2 --fontFamily-googleInter"
							md-font="normal 700 7.5vw/1.2 --fontFamily-googleInter"
							margin="10px 0"
							lg-font="normal 700 60px/1.2 &quot;Inter&quot;, sans-serif"
						>
							Panosta itseesi - valitse hyvinvointipalvelumme
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				text-align="center"
				margin="25vh 0 0 0"
				position="absolute"
				bottom="20px"
				left={0}
				right={0}
			>
				<Text margin="8px 0" text-transform="uppercase" sm-font="15px/27px Inter, sans-serif">
					Mikä on cryostudio?
				</Text>
				<Icon category="md" margin="0 auto" icon={MdArrowDownward} />
			</Box>
		</Section>
		<Section quarkly-title="Slider Section 16:9" padding="60px 0 0 0" md-padding="30px 0 0 0">
			<Override slot="SectionContent" />
			<Hr min-height="10px" min-width="100%" margin="0px 0px 0px 0px" />
			<Text
				font="--headline2"
				color="--indigo"
				md-font="normal 700 6.5vw/1.2 &quot;Inter&quot;, sans-serif"
				sm-margin="0 0px 0x 0px"
				md-margin="0 0px 0px 0px"
				text-align="center"
			>
				Cryostudio Joensuu
			</Text>
			<Text
				color="rgba(0, 0, 0, 0.69)"
				letter-spacing="1px"
				margin="0 0 30px 0"
				sm-font="14px/27px Inter, sans-serif"
				max-width="80%"
				font="--lead"
				lg-max-width="70%"
				md-max-width="100%"
				md-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
				md-margin="10px 0 15px 0"
				text-align="center"
				flex="0 1 auto"
				align-self="center"
			>
				Tervetuloa hoitolaitokseen, jossa hyvinvointiin liittyvät tarpeesi huomioidaan kokonaisvaltaisesti. Tarjoamme monipuolisia hoitoja, jotka auttavat sinua palautumaan, hallitsemaan kipuja sekä muokkaamaan kehoasi parhaaksi mahdolliseksi versioksi itsestäsi. Löydä tasapainoinen hyvinvointi meidän avullamme.
			</Text>
			<Box min-width="100px" min-height="50px">
				<Hr min-height="10px" min-width="100%" margin="0px 0px 0px 0px" />
			</Box>
			<Components.QuarklycommunityKitCarousel
				display="flex"
				align-items="flex"
				flex-direction="column"
				showHead={false}
				showText={false}
				showLink={false}
				slidesProp="3"
				slidesWrapper="box"
				autoPlay
				autoPlayIntervalProp="3s"
			>
				<Override slot="Slides" width="100%" />
				<Override slot="Slide Image 1" src="https://uploads.quarkly.io/6448bc7abf72b300207e257c/images/pCryostudion%20bannerit%20%281600%20x%20900%20mm%29%20%283%29%20%281%29-min.jpg?v=2023-11-22T19:35:14.948Z" />
				<Override slot="Slide Image 2" src="https://uploads.quarkly.io/6448bc7abf72b300207e257c/images/Cryostudion%20bannerit%20%2811%29.jpg?v=2024-10-01T12:14:11.382Z" />
				<Override slot="Slide Image 3" src="https://uploads.quarkly.io/6448bc7abf72b300207e257c/images/p1%20%281%29.png?v=2023-11-22T19:35:14.941Z" />
				<Override slot="Slide Image,Slide Image 2" src="https://uploads.quarkly.io/6448bc7abf72b300207e257c/images/Cryostudion%20bannerit%20%2811%29.jpg?v=2024-10-01T12:14:11.382Z" />
				<Override slot="Slide Image" src="https://uploads.quarkly.io/6448bc7abf72b300207e257c/images/Cryostudion%20bannerit%20%2811%29.jpg?v=2024-10-01T12:14:11.382Z" />
			</Components.QuarklycommunityKitCarousel>
		</Section>
		<Section id="about" lg-padding="60px 0 40px 0" padding="60px 0 60PX 0" md-padding="30px 0 30px 0">
			<Override slot="SectionContent" />
			<Text
				font="--headline2"
				color="--indigo"
				padding="0px 0px 20px 0px"
				md-font="normal 700 6.5vw/1.2 &quot;Inter&quot;, sans-serif"
				sm-margin="0 0px 0x 0px"
				md-margin="0 0px 0px 0px"
			>
				Palvelumme
			</Text>
			<Box
				margin="0px 0px 20px 0px"
				sm-margin="0px 0px 30px 0px"
				border-radius="10px"
				min-height="284px"
				md-border-radius="0px"
				padding="0px 0px 0px 0px"
				border-style="solid"
				display="flex"
				border-width="2px"
				flex-wrap="wrap"
				md-border-width="0px 0px 2px 0px"
				width="100%"
				border-color="rgba(66, 82, 207, 0.1)"
				md-border-color="rgba(66, 82, 207, 0.1)"
			>
				<Box display="flex" lg-width="50%" md-width="100%" width="30%">
					<Image
						margin="-1px 0px -1px -1px"
						src="https://uploads.quarkly.io/6448bc7abf72b300207e257c/images/pcryoTasma.jpg?v=2023-11-22T19:35:14.935Z"
						object-fit="cover"
						width="100%"
						border-radius="10px"
						md-margin="0px 0px 0px 0px"
						srcSet="https://smartuploads.quarkly.io/6448bc7abf72b300207e257c/images/pcryoTasma.jpg?v=2023-11-22T19%3A35%3A14.935Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6448bc7abf72b300207e257c/images/pcryoTasma.jpg?v=2023-11-22T19%3A35%3A14.935Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6448bc7abf72b300207e257c/images/pcryoTasma.jpg?v=2023-11-22T19%3A35%3A14.935Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6448bc7abf72b300207e257c/images/pcryoTasma.jpg?v=2023-11-22T19%3A35%3A14.935Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6448bc7abf72b300207e257c/images/pcryoTasma.jpg?v=2023-11-22T19%3A35%3A14.935Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6448bc7abf72b300207e257c/images/pcryoTasma.jpg?v=2023-11-22T19%3A35%3A14.935Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6448bc7abf72b300207e257c/images/pcryoTasma.jpg?v=2023-11-22T19%3A35%3A14.935Z&quality=85&w=3200 3200w"
						sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					padding="24px 17% 32px 62px"
					width="70%"
					justify-content="space-between"
					md-width="100%"
					md-padding="32px 30px 32px 0px"
					display="flex"
					flex-direction="column"
					position="relative"
					lg-padding="32px 30px 32px 32px"
					lg-width="50%"
				>
					<LinkBox
						bottom="auto"
						lg-right="16px"
						lg-top="px"
						position="absolute"
						right="32px"
						top="32px"
						left="auto"
						all
						href="#xcryo"
					>
						<Image
							width="48px"
							height="48px"
							top="32px"
							left="auto"
							lg-height="24px"
							src="https://uploads.quarkly.io/6123949e957019001e284458/images/akar-icons_arrow-right.svg?v=2021-09-22T00:57:24.639Z"
							position="static"
							right="32px"
							bottom="auto"
							lg-width="24px"
						/>
					</LinkBox>
					<Text
						color="--indigo"
						width="85%"
						lg-font="600 22px/1.3 &quot;Inter&quot;, sans-serif"
						margin="0px 0px 8px 0px"
						font="--headline4"
					>
						{" "}X°Cryo™ kylmähoito
					</Text>
					<Text margin="0px 0px 0px 0px" font="--textBase" color="--darkL1" border-color="#222db9">
						- Urheilullinen suorituskyky paranee
						<br />
						- Nopeuttaa palautumista ja aineenvaihduntaa
						<br />
						- Helpottaa kroonista kipua ja niveltulehduksia
						<br />
						- Vähentää turvotusta ja nestekertymiä
						<br />
						- Nopeuttaa toipumista leikkauksista ja vammoista
						<br />
						- Helpottaa myös iho-oireita kuten aknea tai psoriasista
						<br />
						- Parantaa ihon laatua ja estää ikääntymistä
						<br />
						<br />
					</Text>
					<Box display="flex" align-items="center">
						<Link
							href="https://vello.fi/cryostudio-joensuu/"
							color="#ffffff"
							font="--base"
							text-decoration-line="initial"
							target="_blank"
							md-font="normal 500 14px/1.5 &quot;Inter&quot;, sans-serif"
							sm-font="normal 500 14px/1.5 &quot;Inter&quot;, sans-serif"
							text-align="left"
							border-width="4px"
							border-radius="4px"
							border-style="solid"
							background="#004075"
							border-color="#004075"
							flex="0 1 auto"
							align-self="flex-start"
						>
							{" "}Varaa aika{" "}
						</Link>
					</Box>
				</Box>
			</Box>
			<Box
				display="flex"
				sm-margin="0px 0px 30px 0px"
				padding="0px 0px 0px 0px"
				md-border-width="0px 0px 2px 0px"
				margin="0px 0px 20px 0px"
				width="100%"
				border-style="solid"
				border-color="rgba(66, 82, 207, 0.1)"
				border-width="2px"
				border-radius="10px"
				flex-wrap="wrap"
				min-height="284px"
				md-border-radius="0px"
			>
				<Box
					padding="32px 17% 32px 62px"
					width="70%"
					display="flex"
					position="relative"
					md-padding="32px 30px 32px 0px"
					flex-direction="column"
					justify-content="space-between"
					lg-padding="32px 30px 32px 32px"
					lg-width="50%"
					md-width="100%"
				>
					<LinkBox
						lg-right="16px"
						lg-top="49px"
						position="absolute"
						right="32px"
						top="32px"
						left="auto"
						bottom="auto"
						all
						href="#ecabin"
					>
						<Image
							src="https://uploads.quarkly.io/6123949e957019001e284458/images/akar-icons_arrow-right.svg?v=2021-09-22T00:57:24.639Z"
							left="auto"
							lg-height="24px"
							top="32px"
							bottom="auto"
							lg-width="24px"
							width="48px"
							height="48px"
							position="static"
							right="32px"
						/>
					</LinkBox>
					<Text
						margin="0px 0px 8px 0px"
						font="--headline4"
						color="--indigo"
						width="85%"
						lg-font="600 22px/1.3 &quot;Inter&quot;, sans-serif"
					>
						e°CABIN{"\n\n"}
					</Text>
					<Text margin="0px 0px 0px 0px" font="--textBase" color="--darkL1">
						- Urheilullinen suorituskyky paranee
						<br />
						- Nopeuttaa palautumista ja aineenvaihduntaa
						<br />
						- Helpottaa kroonista kipua ja niveltulehduksia
						<br />
						- Vähentää turvotusta ja nestekertymiä
						<br />
						- Nopeuttaa toipumista leikkauksista ja vammoista
						<br />
						- Helpottaa myös iho-oireita kuten aknea tai psoriasista
						<br />
						<br />
					</Text>
					<Link
						href="https://vello.fi/cryostudio-joensuu/"
						color="#ffffff"
						font="--base"
						text-decoration-line="initial"
						target="_blank"
						md-font="normal 500 14px/1.5 &quot;Inter&quot;, sans-serif"
						sm-font="normal 500 14px/1.5 &quot;Inter&quot;, sans-serif"
						text-align="left"
						border-width="4px"
						border-radius="4px"
						border-style="solid"
						background="#004075"
						border-color="#004075"
						flex="0 1 auto"
						align-self="flex-start"
					>
						{" "}Varaa aika{" "}
					</Link>
				</Box>
				<Box
					md-order="-1"
					width="30%"
					display="flex"
					lg-width="50%"
					md-width="100%"
				>
					<Image
						object-fit="cover"
						width="100%"
						border-radius="10px"
						md-margin="0px 0px 0px 0px"
						margin="-1px -1px -1px 0px"
						src="https://uploads.quarkly.io/6448bc7abf72b300207e257c/images/peCabin.JPG?v=2023-11-22T19:35:14.947Z"
						srcSet="https://smartuploads.quarkly.io/6448bc7abf72b300207e257c/images/peCabin.JPG?v=2023-11-22T19%3A35%3A14.947Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6448bc7abf72b300207e257c/images/peCabin.JPG?v=2023-11-22T19%3A35%3A14.947Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6448bc7abf72b300207e257c/images/peCabin.JPG?v=2023-11-22T19%3A35%3A14.947Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6448bc7abf72b300207e257c/images/peCabin.JPG?v=2023-11-22T19%3A35%3A14.947Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6448bc7abf72b300207e257c/images/peCabin.JPG?v=2023-11-22T19%3A35%3A14.947Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6448bc7abf72b300207e257c/images/peCabin.JPG?v=2023-11-22T19%3A35%3A14.947Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6448bc7abf72b300207e257c/images/peCabin.JPG?v=2023-11-22T19%3A35%3A14.947Z&quality=85&w=3200 3200w"
						sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="100%"
				border-style="solid"
				border-radius="10px"
				min-height="284px"
				md-border-radius="0px"
				margin="0px 0px 20px 0px"
				sm-margin="0px 0px 30px 0px"
				padding="0px 0px 0px 0px"
				border-color="rgba(66, 82, 207, 0.1)"
				border-width="2px"
				flex-wrap="wrap"
				md-border-width="0px 0px 2px 0px"
			>
				<Box md-width="100%" width="30%" display="flex" lg-width="50%">
					<Image
						margin="-1px 0px -1px -1px"
						src="https://uploads.quarkly.io/6448bc7abf72b300207e257c/images/Xtone.jpg?v=2023-11-22T19:35:14.937Z"
						object-fit="cover"
						width="100%"
						border-radius="10px"
						md-margin="0px 0px 0px 0px"
						srcSet="https://smartuploads.quarkly.io/6448bc7abf72b300207e257c/images/Xtone.jpg?v=2023-11-22T19%3A35%3A14.937Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6448bc7abf72b300207e257c/images/Xtone.jpg?v=2023-11-22T19%3A35%3A14.937Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6448bc7abf72b300207e257c/images/Xtone.jpg?v=2023-11-22T19%3A35%3A14.937Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6448bc7abf72b300207e257c/images/Xtone.jpg?v=2023-11-22T19%3A35%3A14.937Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6448bc7abf72b300207e257c/images/Xtone.jpg?v=2023-11-22T19%3A35%3A14.937Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6448bc7abf72b300207e257c/images/Xtone.jpg?v=2023-11-22T19%3A35%3A14.937Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6448bc7abf72b300207e257c/images/Xtone.jpg?v=2023-11-22T19%3A35%3A14.937Z&quality=85&w=3200 3200w"
						sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					md-width="100%"
					padding="32px 17% 32px 62px"
					display="flex"
					lg-width="50%"
					position="relative"
					lg-padding="32px 30px 32px 32px"
					md-padding="32px 30px 32px 0px"
					width="70%"
					flex-direction="column"
					justify-content="space-between"
				>
					<LinkBox
						position="absolute"
						right="32px"
						top="32px"
						bottom="auto"
						left="auto"
						lg-right="16px"
						lg-top="49px"
						all
						href="#xtone"
					>
						<Image
							lg-width="24px"
							lg-height="24px"
							right="32px"
							top="32px"
							left="auto"
							bottom="auto"
							width="48px"
							height="48px"
							src="https://uploads.quarkly.io/6123949e957019001e284458/images/akar-icons_arrow-right.svg?v=2021-09-22T00:57:24.639Z"
							position="static"
						/>
					</LinkBox>
					<Text
						margin="0px 0px 8px 0px"
						font="--headline4"
						color="--indigo"
						width="85%"
						lg-font="600 22px/1.3 &quot;Inter&quot;, sans-serif"
					>
						CTN X-Tone™
					</Text>
					<Text margin="0px 0px 0px 0px" font="--textBase" color="--darkL1">
						- Kiinteyttää kehoa
						<br />
						- Vähentää selluliittia
						<br />
						- Parantaa ihon elastisuutta
						<br />
						- Vähentää rasvakudosta
						<br />
						- Parantaa verenkiertoa
						<br />
						- Vähentää turvotusta
						<br />
						- Parantaa lihasjäykkyyttä
						<br />
						- Palauttaa
						<br />
						- Parantaa aineenvaihduntaa
						<br />
						<br />
					</Text>
					<Link
						href="https://vello.fi/cryostudio-joensuu/"
						color="#ffffff"
						font="--base"
						text-decoration-line="initial"
						target="_blank"
						md-font="normal 500 14px/1.5 &quot;Inter&quot;, sans-serif"
						sm-font="normal 500 14px/1.5 &quot;Inter&quot;, sans-serif"
						text-align="left"
						border-width="4px"
						border-radius="4px"
						border-style="solid"
						background="#004075"
						border-color="#004075"
						flex="0 1 auto"
						align-self="flex-start"
					>
						{" "}Varaa aika{" "}
					</Link>
				</Box>
			</Box>
		</Section>
		<Section
			id="ecabin"
			padding="140px 0"
			sm-padding="40px 0 40px 0"
			background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6448bc7abf72b300207e257c/images/pmainKuva.jpg?v=2023-11-22T19:35:14.931Z) 0% 0% /cover repeat scroll padding-box"
			display="flex"
			width="100%"
			height="max-content"
			flex-direction="row"
		>
			<Override slot="SectionContent" sm-align-items="center" align-items="flex-start" flex-direction="row-reverse" />
			<Box
				max-width="640px"
				padding="40px 40px 40px 50px"
				background="--color-light"
				color="--dark"
				font="12px sans-serif"
			>
				<Text
					as="h4"
					font="--base"
					color="--grey"
					letter-spacing="1px"
					margin="6px 0"
				>
					e°CABIN
				</Text>
				<Text as="h2" font="--headline4" margin="0 0 2px 0">
					HUIPPUKYLMÄÄ KOKO KEHOLLE
				</Text>
				<Text font="--base">
					e°CABIN



hyvää oloa ja helpottaa vammoista sekä rasituksista toipumista. Hoito on ihanteellista esimerkiksi suorituskyvyn maksimointiin ennen urheilusuoritusta ja lisäksi se helpottaa palautumista rasituksen jälkeen. Hoitolämpötila on noin –120 astetta. Suositeltu hoidon kesto on 2 minuuttia. Hoidon aikana pää on aina kabiinin reunan yläpuolella ja hengitettävä ilma on huoneenlämpöistä.
					<br />
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						{"\n"}e°CABIN

 Hinnasto:
					</Strong>
					<br />
					{"\n"}e°CABIN

 hoito 20€
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Sarjakortit:
					</Strong>
					<br />
					10 x 
e°CABIN

 hoito 150€
				</Text>
				<Link
					href="https://vello.fi/cryostudio-joensuu/"
					color="#ffffff"
					font="--base"
					text-decoration-line="initial"
					target="_blank"
					md-font="normal 500 14px/1.5 &quot;Inter&quot;, sans-serif"
					sm-font="normal 500 14px/1.5 &quot;Inter&quot;, sans-serif"
					text-align="left"
					border-width="4px"
					border-radius="4px"
					border-style="solid"
					background="#004075"
					border-color="#004075"
					flex="0 1 auto"
					align-self="flex-start"
				>
					{" "}Varaa aika{" "}
				</Link>
			</Box>
		</Section>
		<Hr min-height="10px" min-width="100%" margin="0px 0px 0px 0px" />
		<Section id="xcryo" padding="140px 0" sm-padding="40px 0 40px 0" background="url(https://uploads.quarkly.io/6448bc7abf72b300207e257c/images/pMASKI.jpg?v=2023-11-22T19:35:14.929Z) center/cover">
			<Override slot="SectionContent" sm-align-items="center" align-items="flex-end" flex-direction="row" />
			<Box max-width="640px" padding="40px 40px 40px 40px" background="--color-light" color="--dark">
				<Text
					as="h4"
					font="--base"
					color="--grey"
					letter-spacing="1px"
					text-transform="uppercase"
					margin="6px 0"
				>
					X°Cryo™
				</Text>
				<Text as="h2" font="--headline4" margin="0 0 4px 0">
					HUIPPUKYLMÄÄ PAIKALLISESTI
				</Text>
				<Text font="--base">
					Cryotech Nordicin X°Cryo™ on paikallinen huippukylmähoitolaite. Sitä käytetään kivun, turvotusten sekä tulehduksien parantamiseen ilman lääkkeitä.
					<br />
					Hoito on tutkitusti turvallinen ja tehokas ja sen perusperiaate on yksinkertainen. Kylmähoito aiheuttaa kehon pintasolukon thermoshokin eli simuloidun hätätilan. Solut reagoivat siten, että parasympaattinen hermosto lähettää hätäsignaalin, mikä saa kehon tuottamaan useita hyvinvointia lisääviä hormoneja ja entsyymejä kuten adrenaliinia, endorfiineja, kortisolia ja testosteronia. Näiden lisääntyminen verenkierrossa auttaa paranemista vammoista, leikkauksista ja tulehduksista.
					<br />
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Cryohoito hinnasto:
					</Strong>
					<br />
					Cryohoito 25€
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Sarjakortit:
					</Strong>
					<br />
					3 x Cryohoito 50€
					<br />
					5 x Cryohoito 80€
					<br />
					10 x Cryohoito 145€
				</Text>
				<Link
					href="https://vello.fi/cryostudio-joensuu/"
					color="#ffffff"
					font="--base"
					text-decoration-line="initial"
					target="_blank"
					md-font="normal 500 14px/1.5 &quot;Inter&quot;, sans-serif"
					sm-font="normal 500 14px/1.5 &quot;Inter&quot;, sans-serif"
					text-align="left"
					border-width="4px"
					border-radius="4px"
					border-style="solid"
					background="#004075"
					border-color="#004075"
					flex="0 1 auto"
					align-self="flex-start"
				>
					{" "}Varaa aika{" "}
				</Link>
			</Box>
		</Section>
		<Hr min-height="10px" min-width="100%" margin="0px 0px 0px 0px" />
		<Section id="xtone" padding="140px 0" sm-padding="40px 0 40px 0" background="url(https://uploads.quarkly.io/6448bc7abf72b300207e257c/images/Xtone.jpg?v=2023-05-19T16:23:34.678Z) center/cover">
			<Override slot="SectionContent" sm-align-items="center" align-items="center" />
			<Box max-width="650px" padding="40px 40px 40px 40px" background="--color-light" color="--dark">
				<Text
					as="h4"
					font="--base"
					color="--grey"
					letter-spacing="1px"
					text-transform="uppercase"
					margin="6px 0"
				>
					CTN X-Tone™
				</Text>
				<Text as="h2" font="--headline4" margin="0 0 2px 0">
					MUOKKAA-,
					<br />
					PALAUTA- JA
					<br />
					HOIDA KEHOA
				</Text>
				<Text font="--base">
					CTN X-Tone™ on uusi MMS (Magnetic Muscle Stimulation) laite kehonmuokkaukseen. CTN X-tone ™ -hoito tuhoaa rasvaa ja kehittää lihaksia samanaikaisesti. X-Tone™ supistaa lihaksia yli sen, mikä on mahdollista normaalin treenin kautta ja antaa paremman lihasvasteen, sekä kiihdyttää myös rasvasolujen rikkoutumista.{" "}
					<br />
					<br />
					Erilaiset valmiiksi ohjelmoidut hoito-ohjelmat mahdollistavat monipuolisen, tehokkaan ja helpon harjoittelun. Hoito ei hapota lihasta samalla lailla, kuten tavallinen fyysinen harjoittelu, mutta pientä lihasten arkuutta voi hoitojen jälkeen ilmetä. Hoito ei aiheuta kipua tai epämukavuutta.
					<br />
					<br />
					Hoidosta on tehty useita tutkimuksia, ja CTN X-Tone on kliinisesti testattu turvallisuuden ja tehon varmistamiseksi. Tutkimuksissa on saavutettu mm. seuraavia tuloksia:
					<br />
					- 19% rasvan väheneminen
					<br />
					- 16% lihasmassan kasvu
					<br />
					- 11% vatsalihasten erkanemisen väheneminen synnyttäneillä naisilla
					<br />
					- 80% hoidettavista asiakkaista todettiin selvästi näkyvä pakaralihasten kiinteytys ja profiilin kohoaminen.
					<br />
					<br />
					<Strong>
						Mikäli sinulla on jokin näistä, X-TONE™-hoitoa ei voida toteuttaa
					</Strong>
					<br />
					- Sydämentahdistin ja muut sähköiset laitteet kehossa
					<br />
					- Vakavat sydän- ja verisuonitaudit
					<br />
					- Epilepsia
					<br />
					- Laskimotukos
					<br />
					- Raskaus
					<br />
					- Elimistön tulehdustila/kuume
					<br />
					- Valtimotauti (ateroskleroosi)
					<br />
					- Verenvuototauti (hemofilia)
					<br />
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						X-tone hinnasto:
					</Strong>
					<br />
					X-Tone hoito 30 min 65€
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Sarjakortit:
					</Strong>
					<br />
					3 x 30 min X-Tone hoito 150€
					<br />
					5 x 30 min X-Tone hoito 225€
					<br />
					10 x 30 min X-Tone hoito 400€
				</Text>
				<Link
					href="https://vello.fi/cryostudio-joensuu/"
					color="#ffffff"
					font="--base"
					text-decoration-line="initial"
					target="_blank"
					md-font="normal 500 14px/1.5 &quot;Inter&quot;, sans-serif"
					sm-font="normal 500 14px/1.5 &quot;Inter&quot;, sans-serif"
					text-align="left"
					border-width="4px"
					border-radius="4px"
					border-style="solid"
					background="#004075"
					border-color="#004075"
					flex="0 1 auto"
					align-self="flex-start"
				>
					{" "}Varaa aika{" "}
				</Link>
			</Box>
		</Section>
		<div id="acout-section">
			<Section
				id="contact"
				color="--light"
				padding="60px 0 60px 0"
				sm-padding="40px 0 40px 0"
				position="relative"
				background="--color-darkBlue"
				margin="60x 0 0 0"
			>
				<Override slot="SectionContent" md-align-items="center" />
				<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap" width="100%">
					<Box padding="30px 16px 30px 16px" width="50%" md-width="100%" sm-padding="30px 0 0 0">
						<Box sm-display="flex" sm-flex-direction="column" sm-align-items="flex-start">
							<Button
								background="#ffffff"
								color="#000000"
								padding="12px 32px 12px 32px"
								margin="0px 0px 20px 0px"
								border-radius="8px"
								type="link"
								text-decoration-line="initial"
								href="https://vello.fi/cryostudio-joensuu"
								target="_blank"
								border-width="1px"
								border-style="solid"
								border-color="#000000"
								box-shadow="0px 0 30px 10px rgba(255, 255, 255, 0.01)"
							>
								Varaa aika
							</Button>
							<Box
								padding="0 0 0 64px"
								sm-padding="64px 0 0 0"
								margin="32px 0 0 0"
								max-width="360px"
								position="relative"
								sm-display="flex"
								sm-flex-direction="column"
								sm-width="100%"
								sm-max-width="100%"
							>
								<Icon
									position="absolute"
									size="48px"
									top="0"
									left="0"
									category="md"
									icon={MdLocationOn}
								/>
								<Text as="h4" margin="6px 0" font="--base" sm-width="100%">
									Osoite
								</Text>
								<Text as="p" margin="6px 0" font="--headline5" sm-width="100%">
									Teollisuuskatu 1
									<br />
									{" "}80100
									<br />
									Joensuu
								</Text>
							</Box>
							<Box
								padding="0 0 0 64px"
								sm-padding="64px 0 0 0"
								margin="64px 0 0 0"
								max-width="360px"
								position="relative"
								sm-display="flex"
								sm-flex-direction="column"
								sm-width="100%"
								sm-max-width="100%"
							>
								<Icon
									position="absolute"
									size="48px"
									top="0"
									left="0"
									category="md"
									icon={MdEmail}
								/>
								<Text as="h4" margin="6px 0" font="--base" sm-width="100%">
									Sposti
								</Text>
								<Text as="p" margin="6px 0" font="--headline5">
									<Link
										href="mailto:cryostudiojoensuu@gmail.com"
										text-decoration="none"
										hover-text-decoration="underline"
										color="--light"
										sm-width="100%"
									>
										cryostudiojoensuu@gmail.com
									</Link>
								</Text>
							</Box>
							<Box
								padding="0 0 0 64px"
								margin="64px 0 0 0"
								max-width="360px"
								position="relative"
								sm-display="flex"
								sm-flex-direction="column"
								sm-width="100%"
								sm-max-width="100%"
								sm-padding="64px 0 0 0"
							>
								<Icon
									position="absolute"
									size="48px"
									top="0"
									left="0"
									category="md"
									icon={MdPhone}
									sm-display="block"
								/>
								<Text as="h4" margin="6px 0" font="--base" sm-width="100%">
									Soita meille
								</Text>
								<Text as="p" margin="6px 0" font="--headline5" sm-width="100%">
									(013) 123 258
								</Text>
							</Box>
						</Box>
						<SocialMedia facebook="https://www.facebook.com/profile.php?id=100089236485919" instagram="https://www.instagram.com/cryostudiojoensuu/" align-items="flex-center" justify-content="flex-center">
							<Override
								slot="link"
								margin="48px 48px 48px 48px"
								hover-background="--color-indigo"
								background="--color-wihte"
								lg-height="42px"
								lg-padding="4px 4px 4px 4px"
								lg-display="flex"
								lg-align-items="center"
								border-radius="50%"
								lg-justify-content="right"
								lg-width="42px"
								color="--light"
							/>
							<Override slot="icon" lg-font="48px sans-serif" />
						</SocialMedia>
					</Box>
					<Box width="50%" padding="30px 8px 30px 8px" md-width="100%" sm-padding="0 0px 30px 0px">
						<Box>
							<Box
								margin="0 0 0 auto"
								md-max-width="100%"
								sm-padding="24px 0 36px 0"
								padding="0 24px 0 24px"
								lg-padding="0 0px 0 0"
								lg-max-width="100%"
							>
								<Text
									as="h3"
									font="--base"
									margin="0 0 20px 0"
									sm-padding="0px 8px 0px 8px"
									sm-margin="0 0 8px 0"
									text-align="left"
								>
									<Strong
										overflow-wrap="normal"
										word-break="normal"
										white-space="normal"
										text-indent="0"
										text-overflow="clip"
										hyphens="manual"
										user-select="auto"
										pointer-events="auto"
									>
										JÄTÄ MEILLE VIESTI JA VASTAAMME MAHDOLLISIMMAN PIAN
									</Strong>
								</Text>
								<Formspree endpoint="https://formspree.io/f/mayzakbo">
									<Box gap="16px" display="flex" flex-direction="row" flex-wrap="wrap">
										<Box padding="8px 8px 8px 8px" width="100%" display="flex" flex-direction="column">
											<Text font="--base" margin="0 0 4px 0">
												Nimi
											</Text>
											<Input width="100%" name="name" type="text" />
										</Box>
										<Box padding="8px 8px 8px 8px" width="100%" display="flex" flex-direction="column">
											<Text font="--base" margin="0 0 4px 0">
												Sähköpostiosoite
											</Text>
											<Input width="100%" type="email" name="email" />
										</Box>
										<Box padding="8px 8px 8px 8px" width="100%" display="flex" flex-direction="column">
											<Text font="--base" margin="0 0 4px 0">
												Viesti
											</Text>
											<Input as="textarea" rows="4" width="100%" name="message" />
										</Box>
										<Box
											padding="8px 8px 8px 8px"
											width="100%"
											display="flex"
											flex-direction="column"
											align-items="flex-end"
											margin="8px 0px 0px 0px"
										>
											<Button
												background="--color-darkBlue"
												border-width="2px"
												border-style="solid"
												border-color="#e2e2e2"
												border-radius="8px"
											>
												Lähetä
											</Button>
										</Box>
									</Box>
								</Formspree>
							</Box>
						</Box>
					</Box>
				</Box>
			</Section>
		</div>
		<Components.QuarklycommunityKitNetlifyForm />
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6123949e957019001e284456"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<script
				async={true}
				src={"https://assets.calendly.com/assets/external/widget.js"}
				type={"text/javascript"}
				place={"endOfHead"}
				rawKey={"645e062fd460d91dc3764761"}
			/>
			<script async={true} src={"https://www.googletagmanager.com/gtag/js?id=G-BVE7PCSLN2"} place={"endOfBody"} rawKey={"6462926d2c28a7295f059af8"} />
			<script place={"endOfBody"} rawKey={"6462929dff2fe05084f2d64a"}>
				{"  window.dataLayer = window.dataLayer || [];\n  function gtag(){dataLayer.push(arguments);}\n  gtag('js', new Date());\n\n  gtag('config', 'G-BVE7PCSLN2');"}
			</script>
			<script src={""} type={""} place={"endOfBody"} rawKey={"65d365a93da36c13c7620736"}>
				{"window.embeddedChatbotConfig = {\nchatbotId: \"rC9x_AOE_XXE_CHCXF7M-\",\ndomain: \"www.chatbase.co\"\n}\n"}
			</script>
			<script src={"https://www.chatbase.co/embed.min.js"} place={"endOfBody"} rawKey={"65d36b11ad6e5408f94b06f0"} />
		</RawHtml>
	</Theme>;
});